<template>
  <div class="custom-otp-container" @click="focusNow">
      <input disabled maxlength="1" @paste="(e)=>{ onPaste(e, index) }" :ref="'input-' + index" @keydown="(e)=>{ removeValue(e, index) }" @input="(e)=>{ change(e, index) }" @change="(e)=>{ change(e, index) }" v-for="index in length" :key="index" type="number">
  </div>
</template>
<script>
export default {
  props : ['length', 'verify'],
  data : function(){
    return {
      setVal : _.debounce(this.setNow, 200)
    }
  },
  methods : {
    getValue(){
      let val = "";
      _.each(this.$refs, (r)=>{
        if(r.value){
          val += r.value
        }
      });
      return val
    },
    focusNow(){
      let found = false;
      let hasValue = 0;

      _.each(this.$refs, (r)=>{

        if(r.value){
          hasValue ++
        };
        if(!r.value && !found){
          found = true
          r.disabled = false;
          r.focus();
        }
      });

      if(hasValue == this.length){
        this.$refs['input-' + this.length].focus();
      };
    },
    setNow(value){
      if(value && value.toString().length == this.length && this.verify){
        this.verify(this.getValue());
      };
    },
    removeValue(event, index){
      let check = event.which || event.keyCode || event.charCode;

      if(event.target.value && check != 8){

        if(event.target.value){
          setTimeout(()=>{
            this.$refs['input-' + index].disabled = true;
            this.$refs['input-' + (parseInt(index) + 1)].disabled = false;
            this.$refs['input-' + (parseInt(index) + 1)].value = event.key;
            this.$refs['input-' + (parseInt(index) + 1)].focus()
          }, 1)
        }

        event.preventDefault()
        return;
      }

      if(check == 8 && index != 1){
        event.target.value = null;
        setTimeout(()=>{
          this.$refs['input-' + index].disabled = true;
          this.$refs['input-' + (parseInt(index) - 1)].disabled = false;
          this.$refs['input-' + (parseInt(index) - 1)].focus()
        }, 1)
      };

    },
    fillOTPInput(pastedData, index){
      let d = pastedData.split('');
      _.each(d, (v, ind)=>{
         this.$refs['input-' + (ind + 1)].disabled = true;
         this.$refs['input-' + (ind + 1)].value = v;
         if((ind + 1) == this.length){
            this.$refs['input-' + (ind + 1)].disabled = false;
            this.setVal(pastedData);
         };
      });
    },
    onPaste(event, index){
      var clipboardData, pastedData;
      // Stop data actually being pasted into div
      event.stopPropagation();
      event.preventDefault();
      // Get pasted data via clipboard API
      clipboardData = event.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      if(pastedData){
        pastedData = pastedData.replace(/[^0-9]+/g, '')
      }

      if(index == 1 && pastedData && pastedData.toString().length == this.length){
        this.fillOTPInput(pastedData, index);
      }
    },
    change(event, index){
       let val = event.target.value.trim()
       val = val.replace(/[^0-9]+/g, '');
       event.target.value = val
       
       if(event.target.value && event.target.value.toString().length == this.length && index == 1){
        event.stopPropagation();
        event.preventDefault();
        return this.fillOTPInput(val, index);
       }

       if(event.target.value && index != this.length){
         this.$refs['input-' + index].disabled = true;
         this.$refs['input-' + (parseInt(index) + 1)].disabled = false;
         this.$refs['input-' + (parseInt(index) + 1)].focus()
       }

       if(event.target.value && index == this.length){
        this.$refs['input-' + index].blur()
       }

       if(index == this.length && event.target.value){
        let v = _.map(this.$refs, (r)=>{
          return r.value;
        }).join('');
        this.setVal(v);
       }
    }
  }
}
</script>

<style scoped>
.custom-otp-container{
    display: flex;
    align-items: center;
    justify-content: center;

}

.custom-otp-container input:disabled{
    background: transparent;
}

.custom-otp-container input{
     background: transparent;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    width: 35px;
    margin-right: 20px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    border: none;
    outline: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    font-weight: normal;
    font-family: inherit;
    border-radius: 0px !important;
}
    

.custom-otp-container input:last-child{
    margin-right: 0px;
}


.custom-otp-container input::-webkit-outer-spin-button,
.custom-otp-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom-otp-container input[type=number] {
  -moz-appearance: textfield;
}
</style>