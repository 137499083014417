<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-4">
          <div class="row justify-content-center mb-3 mt-2">
            <img
              src="img/brand/logo.png"
              style="height: 70px"
              alt="..."
              class="c-pointer"
              @click="$r.changeRoute('home')"
            />
          </div>

          <template v-if="!is2FA">
            <div class="text-center text-muted mb-3">
              <small>{{ $t.translate("TTL_LOGIN_HEADER_EMAIL") }}</small>
            </div>

            <el-alert
              v-if="isSuccessActivation"
              :title="$t.translate('LBL_ACC_ACTIVATED_LOGIN_NOW')"
              type="success"
              show-icon
              style="margin-bottom: 10px"
              :closable="false"
            ></el-alert>

            <el-alert
              v-if="isSuccessReg"
              :title="$t.translate('MSG_VERIFY_EMAIL_SENT')"
              type="success"
              show-icon
              style="margin-bottom: 10px"
              :closable="false"
            ></el-alert>

            <el-alert
              v-if="isPasswordSet"
              :title="$t.translate('MSG_PASSWORD_SUCCESSFULLY_CHANGED')"
              type="success"
              show-icon
              style="margin-bottom: 10px"
              :closable="false"
            ></el-alert>

            <form role="form">
              <base-input
                v-model="formData.Email"
                formClasses="input-group-alternative mb-3"
                type="email"
                :placeholder="$t.translate('FRM_EMAIL')"
                addon-left-icon-old="ni ni-single-02"
                addon-left-icon="fas fa-envelope"
              >
              </base-input>

              <base-input
                v-model="formData.Password"
                formClasses="input-group-alternative mb-3"
                :placeholder="$t.translate('FRM_PASSWORD')"
                type="password"
                :reveal="true"
                addon-left-icon="ni ni-lock-circle-open"
              >
              </base-input>

              <div class="text-center">
                <el-button
                  type="primary"
                  class="mt-3 mb-1 f-btn-common f-btn-orange"
                  @click="signIn"
                  >{{ $t.translate("BTN_LOGIN") }}</el-button
                >
              </div>
            </form>
          </template>

          <template v-else>
            <div class="text-center text-muted mb-4">
              <small>{{ $t.translate("TTL_OTP_LOGIN") }}</small>
            </div>
            <form role="form">
              <p>{{ $t.translate("MSG_OTP_SENT_TO_PHONE") }} {{ formData.Last4Digit }}</p>

              <OtpInput :length="6" :verify="handleOnComplete" />
              <div class="text-center" style="margin-top: 10px">
                <el-button type="text" :disabled="!enableResend" @click="resend"
                  >{{ $t.translate("BTN_REQUEST_OTP") }}
                  <span v-if="countdown > 0">({{ countdown }})</span></el-button
                >
              </div>

              <div class="text-center">
                <el-button
                  type="primary"
                  class="mt-3 mb-2 f-btn-common f-btn-orange"
                  @click="checkCode"
                  >{{ $t.translate("BTN_CONFIRM") }}</el-button
                >
              </div>
            </form>
          </template>
        </div>
      </div>

      <div class="d-flex justify-content-between my-3">
        <a
          class="h5 text-muted"
          href="javascript:void(0)"
          @click="$r.changeRoute('register')"
          >{{ $t.translate("BTN_REGISTER_USER_ACC") }}</a
        >
        <a
          class="h5 text-muted"
          href="javascript:void(0)"
          @click="$r.changeRoute('forgot')"
          >{{ $t.translate("BTN_FORGOT_PASSWORD") }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { router } from "@/utils/router";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

// import TC from "@/components/base/TC";
// import Disclaimer from "@/components/base/Disclaimer";
import OtpInput from "@/components/OtpInput";

//import CountryCode from "@/components/CountryCode";

let interval = null;

export default {
  components: {
    // Disclaimer,
    // TC,
    OtpInput,
    //CountryCode
  },
  setup() {
    const route = useRoute();
    let { isSuccessReg, isPasswordSet, isSuccessActivation } = store.getters[
      "page/getData"
    ];

    let formData = reactive({
      Code: "",
      Token: "",
      Email: "",
      Password: "",
      MobileCode: "",
      MobileNumber: "",
    });

    let showModal = ref(false);
    let showModalTC = ref(false);

    let is2FA = ref(false);
    let countdown = ref(0);
    let enableResend = ref(false);

    const showTimer = () => {
      countdown.value = 60;
      enableResend.value = false;

      if (interval) clearInterval(interval);

      interval = setInterval(() => {
        countdown.value -= 1;
        if (countdown.value == 0) {
          enableResend.value = true;
          clearInterval(interval);
        }
      }, 1000);
    };

    const resend = async () => {
      let res = await post("/auth/otp/resend", formData);
      if (!isValidResponse(res)) return;

      helpers.showMessage({
        Code: "MSG_OTP_RESEND_SUCCESS",
        Message: "One-time passcode sent!",
      });
      showTimer();
    };

    const authNow = async (res) => {
      let path = "/dashboard";
      let urlParams = {};

      await store.dispatch("user/setData", { Token: res.Token });
      await store.dispatch("user/fetchData");

      // Dispatch the event.
      const event = new Event("hkqaa-authed");
      window.dispatchEvent(event);

      //check for T&C
      await helpers.checkForTC();

      await helpers.redirect({
        path,
        urlParams,
        router,
      });
    };

    const handleOnComplete = (Code) => {
      formData.Code = Code;
      checkCode();
    };

    const checkCode = async () => {
      if (!formData.Code) return;

      let res = await post("/auth/otp", formData);
      if (!isValidResponse(res)) return;

      await authNow(res);
    };

    const signIn = async () => {
      try {
        let res = await post("/auth/backend/login", formData);
        if (!isValidResponse(res)) return;

        if (res.IsOTPEnabled) {
          is2FA.value = true;
          formData.Token = res.Token;
          formData.Last4Digit = res.Last4Digit;
          showTimer();
        } else {
          await authNow(res);
        }
      } catch (err) {
        helpers.catchError(err);
      }
    };

    onMounted(async () => {
      if (route?.query?.redirect) {
        //set now
        await store.dispatch("user/setData", {
          Redirect: route?.query?.redirect,
        });
      }

      await store.dispatch("page/setData", {
        isSuccessReg: false,
        isSuccessActivation: false,
        isPasswordSet: false,
      });
    });

    return {
      isSuccessReg,
      isPasswordSet,
      isSuccessActivation,

      countdown,
      resend,
      enableResend,
      showModal,
      showModalTC,
      formData,
      signIn,
      is2FA,
      checkCode,
      handleOnComplete,
    };
  },
};
</script>
<style>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #f7fafc !important;
}
</style>
